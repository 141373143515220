<template>
  <div class="layout_bottom">
    <div class="bottom_one">
      <div class="">
        <div class="inner">
          <div class="left_center">
            <div class="center_1">
              <img src="@/assets/images/loanImg/logo_icon_2.png" alt="">
              <h2 class="title">UltraNaira</h2>
            </div>
            <div class="center_2">
              <p>Easy and convenient
                online loan.</p>
              <!-- <div class="center_2_a">
                <a href="/about_us?type=1">《Términos de privacidad》</a>
                <a href="/about_us?type=2">《LAFT》</a>
                <a href="/about_us?type=3">《Contrato de préstamos》</a>
              </div> -->
            </div>
            <div class="item_download" @click="downloadApp()">
              <!-- <img src="@/assets/images/loanImg/icon_download.png" alt=""> -->
              <!-- <p>Descargar APP</p> -->
              <!-- <a href="">
            <img src="@/assets/images/loanImg/download_now.png" alt="">
          </a> -->
              <img src="@/assets/images/loanImg/Google-play.png" alt="">
            </div>
          </div>
          <div class="right_center">
            <p>Contact us</p>
            <div class="items">
              <div>
                <p><img src="@/assets/images/loanImg/email.png">Email：oneconection-sas@outlook.com</p>
                <p><img src="@/assets/images/loanImg/address.png">Dirección：CL 78 No.84 28 AP 401.Bogotá D.C.COLOMBIA</p>
              </div>
              <h4>Copyright © UltraNaira All rights reserved.</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="bottom_two">
      <div class="inner">
        Copyright
      </div>
      <div class="inner">
        <p>DragonFly Tecnology SAS, NIT: 901557034-1 CRA 13 # 97 51 oficina 201 Código postal 110221 - Bogotá, Colombia</p>
        <p>Copyright © 2022 Dragonfly - Prohibida su reproducción total o parcial, así como su traducción a cualquier idioma sin autorización expresa de UltraNaira.</p>
        <p style="font-weight: bold;">El uso de este sitio web implica la aceptación de los Términos y Condiciones, así como la Política de Privacidad de UltraNaira.</p>
        <p>Horarios de atención: Lunes a Viernes de 8 am a 6 pm y Sábados de 8am a 12 pm. No aplica para días festivos.</p>
      </div>
    </div> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  },
  methods: {
    downloadApp() {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.ultranaira.credit.loan'
    }
  }
}
</script>

<style lang="scss" scoped>
.layout_bottom{
  min-width: 1200px;
  .item_download{
    display: flex;
    margin-top: 20px;
    width: 95px;
    // background: black;
    color: white;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    img{
      width: 140px;
      height: 40px;
      margin-right: 5px;
    }
  }
  .bottom_one{
    background: #060300;
    // height: 549x;
    color: #fff;
    padding: 60px 0 60px 0;
    .inner{
      width: 100%;
      padding:0px calc((100% - 1100px) / 2) 0;
      margin: 0 auto;
      overflow: hidden;
      display: flex;
      align-items: center;
    }
    .left_center{
      // float: left;
      width: 15%;
      // border-right: 1px solid #8D8D8D;
      .center_1{
        display: flex;
        img{
          width: 35px;
          height: 35px;
        }
        .title{
          display: flex;
          align-items: center;
          margin-left: 10px;
          width: 250px;
          font-size: 19px;
          line-height: 17px;
        }
      }
      .center_2{
        margin-top: 20px;
      }
      .center_2_a{
        margin-top: 20px;
        a{
          color:white;
          font-size: 12px;
        }
      }
    }
    .right_center{
      overflow: hidden;
      padding-left: 80px;
      .items{
        display: flex;
        img{
          width: 20px;
          vertical-align:middle;
          margin-right: 10px;
        }
        div{
          // width: 40%;
          display: flex;
          flex-direction: column;
          margin-right: 50px;
          line-height: 25px;
          p{
            font-size: 12px;
            margin-bottom: 10px;
          }
        }
      }
      a{
        color: white;
        text-decoration: underline;
      }
      p{
        // margin-top: 30px;
        font-size: 16px;
        line-height: 25px;
        margin-bottom: 15px;
      }
      h4{
        // margin: auto 0 ;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        margin-bottom: 17px;
      }
    }
  }
  .bottom_two{
    padding: 20px 0;
    background: #5c5c5c;
    // height: 45px;
    // text-align: center;
    color: #fff;
    font-size: 14px;
    line-height: 45px;
    .inner{
      width: 100%;
      padding:0px calc((100% - 1100px) / 2) 0;
      margin: 0 auto;
      overflow: hidden;
      p{
        line-height: 30px;
        font-size: 12px;
      }
    }
  }
}
@media only screen and (max-width: 520px){
  .layout_bottom{
    min-width: calc(100vw) !important;
    .bottom_one{
      padding: 20px 0 !important;
      .title,p,.center_2_a a{
        font-size: 12px !important;
      }
      .left_center{
        padding-bottom: 0 !important;
        .item_download{
          margin-top: 10px;
          justify-content: flex-start;
          img{
            height: 45px;
          }
        }
      }
      .right_center{
        p{
          margin-top: 10px;
          margin-bottom: 0;
        }
        flex-direction: column;
        .items{
          flex-direction: column
        }
      }
    }
    .bottom_two{
      div{
        font-size: 12px !important;
      }
    }
    .bottom_one div,.bottom_two div{
      width:calc(100vw - 30px) !important ;
      margin:  0 auto;
      .inner{
        display: flex;
        flex-wrap: wrap;
        .left_center,.right_center{
          width: 100% !important;
          padding-left: 0px;
          padding-top: 0px;
          // padding-bottom: 20px;
          border-right:none;
          h4,p{
            width: 100% !important;
          }
        }
      }
    }
  }

}
</style>
